<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="/logo.png" alt="Öffentliches Zeugnisgeben" />
        <h2 class="mt-6 text-center text-3xl font-extrabold dark:text-gray-100">Melde dich mit deinem Account an</h2>

      </div>
      <Form @submit="onSubmit" :validation-schema="schema" class="mt-8 space-y-6" v-slot="{ errors, isSubmitting }">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <Field placeholder="Email Adresse" name="email" type="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm" :class="{ 'is-invalid': errors.username }" />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <Field placeholder="Passwort" name="password" type="password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm" :class="{ 'is-invalid': errors.username }" />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="text-sm">
            <router-link to="/reset/password" class="font-medium text-teal-600 hover:text-teal-500"> Passwort vergessen? </router-link>
          </div>
        </div>

        <div>
          <button :disabled="isSubmitting" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-teal-500 group-hover:text-teal-400" aria-hidden="true" />
            </span>
            Sign in
          </button>
        </div>
        <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{errors.apiError}}</div>
      </Form>

    </div>
  </div>
</template>

<script setup>
import { LockClosedIcon } from '@heroicons/vue/solid'
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';

import { useAuthStore } from '@/stores';

const schema = Yup.object().shape({
  email: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required')
});

function onSubmit(values, { setErrors }) {
  const authStore = useAuthStore();
  const { email, password } = values;

  return authStore.login(email, password)
      .catch(error => setErrors({ apiError: error }));
}
</script>