<template>
    <div>
      <sidebar-navigation v-if="authStore.user != null" :sidebar-open="sidebarOpen" @sidebar-closed="sidebarOpen=false"></sidebar-navigation>
      <div class="flex flex-col flex-1" :class="authStore.user ? 'md:pl-64': ''">
        <NotificationGroup group="success">
          <div class="fixed inset-x-0 top-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none z-50">
            <div class="w-full max-w-sm">
              <Notification
                  v-slot="{ notifications }"
                  enter="ease-out duration-300 transition"
                  enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                  enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                  move="transition duration-500"
                  move-delay="delay-300"
              >
                <div
                    class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
                    v-for="notification in notifications"
                    :key="notification.id"
                >
                  <div class="flex items-center justify-center w-12 bg-green-500">
                    <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"
                      ></path>
                    </svg>
                  </div>

                  <div class="px-4 py-2 -mx-3">
                    <div class="mx-3">
                      <span class="font-semibold text-green-500">{{notification.title}}</span>
                      <p class="text-sm text-gray-600">{{notification.text}}</p>
                    </div>
                  </div>
                </div>
              </Notification>
            </div>
          </div>
        </NotificationGroup>
        <div class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100 dark:bg-slate-800" v-if="authStore.user != null">
          <button type="button" class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500" @click="sidebarOpen = true">
            <span class="sr-only">Open sidebar</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main class="flex-1">
          <router-view></router-view>
        </main>
      </div>
    </div>
</template>

<script setup>
import { useAuthStore } from '@/stores';
import { Notification, NotificationGroup } from 'notiwind'
import {
  MenuIcon
} from '@heroicons/vue/outline'
import SidebarNavigation from "@/components/SidebarNavigation";
import {ref} from 'vue'

const authStore = useAuthStore();
const sidebarOpen = ref(false);
</script>