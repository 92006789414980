<template>
<div>
  <div class="py-6">
    <action-panel :open="openAction" @action-closed="openAction=false">
      <template v-slot:title>Gerät löschen?</template>
      <template v-slot:message>
        Wenn du das Gerät löschst, werden auch die bestehenden Einsätze gelöscht.<br>
        Möchtest du dieses Gerät wirklich löschen?
      </template>
      <template v-slot:buttons>
        <button type="button" @click="openAction = false" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Abbrechen</button>
        <button type="button" @click="destroyItem(); openAction = false" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm">Löschen</button>
      </template>
    </action-panel>
    <header-component class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-3">
      <template v-slot:title>Geräte</template>
      <template v-slot:buttons>
        <button type="button" @click="openModal = true; itm = {}" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-teal-500">Neues Gerät</button>
      </template>
    </header-component>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <!-- Replace with your content -->
      <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <li v-for="item in items" :key="item.id" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
          <div class="w-full flex items-center justify-between p-6 space-x-6">
            <div class="flex-1 truncate">
              <div class="flex items-center space-x-3">
                <h3 class="text-gray-900 text-sm font-medium truncate">{{ item.name }}</h3>
              </div>
              <p class="mt-1 text-gray-500 text-sm truncate">{{ item.route }}</p>
            </div>
          </div>
          <div>
            <div class="-mt-px flex divide-x divide-gray-200">
              <div class="w-0 flex-1 flex">
                <div @click="openModal = true; itm = item" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer">
                  <PencilIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-3">Bearbeiten</span>
                </div>
              </div>
              <div class="-ml-px w-0 flex-1 flex">
                <div @click="openAction = true; idToDestroy = item.id " class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 cursor-pointer">
                  <TrashIcon class="w-5 h-5 text-red-400" aria-hidden="true" />
                  <span class="ml-3 text-red-400">Löschen</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- /End replace -->
    </div>
  </div>
  <modal-component :open="openModal" @modal-closed="openModal = false">
    <template v-slot:title>Gerät erstellen / bearbeiten</template>
    <template v-slot:description>Hier kannst du Geräte wie Trolleys oder Stände erstellen</template>
    <template v-slot:content>
          <div class="space-y-6 pt-6 pb-5 text-black">
            <div>
              <label for="item-name" class="block text-sm font-medium text-gray-900"> Gerätename </label>
              <div class="mt-1">
                <input type="text" v-model="itm.name" name="item-name" id="item-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
              </div>
            </div>
            <div>
              <label for="description" class="block text-sm font-medium text-gray-900"> Beschreibung </label>
              <div class="mt-1">
                <textarea id="description" v-model="itm.description" name="description" rows="4" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
              </div>
            </div>
            <div>
              <label for="item-route" class="block text-sm font-medium text-gray-900"> Ort </label>
              <div class="mt-1">
                <input type="text" v-model="itm.route" name="item-route" id="item-route" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
              </div>
            </div>
            <div>
            </div>

          </div>
      <div class="flex flex-shrink-0 justify-end px-4 py-4">
        <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2" @click="open = false">Cancel</button>
        <button @click="saveItem" type="submit" class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2">Save</button>
      </div>
    </template>
  </modal-component>
</div>
</template>

<script setup>

import { PencilIcon, TrashIcon } from '@heroicons/vue/solid'
import ModalComponent from "@/components/ModalComponent";
import {onMounted, ref} from "vue";
import {fetchWrapper} from "@/helpers";
import HeaderComponent from "@/components/HeaderComponent";
import ActionPanel from "@/components/ActionPanel";
import {notify} from "notiwind";
const itm = ref([]);
const items = ref([]);
const openModal = ref(false);
const openAction = ref(false);
const idToDestroy = ref(0);
onMounted(async () => {
  items.value = await fetchWrapper.get('items');
})

async function saveItem(){
  let id = itm.value.id || "";
  if(id === ""){
    items.value = await fetchWrapper.post('items/' ,  itm.value)
  }else{
    items.value = await fetchWrapper.put('items/' + id ,  itm.value)
  }
  openModal.value = false;
  notify({
    group: "success",
    title: "Yeah!",
    text: "Das Gerät wurde erfolgreich gespeichert"
  })
}

async function destroyItem(){
  items.value = await fetchWrapper.delete('items/' + idToDestroy.value);
}

</script>

<style scoped>

</style>