<!-- This example requires Tailwind CSS v2.0+ -->
<template>
      <li>
        <a href="#" class="block bg-gray-50 hover:bg-gray-100 dark:bg-slate-600 dark:hover:bg-slate-900">
          <div class="px-4 py-4 flex items-center sm:px-6">
            <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
              <div class="truncate">
                <div class="flex text-sm">
                  <p class="font-medium dark:text-teal-400 truncate">{{ itm.item.name }}</p>
                  <p class="ml-1 flex-shrink-0 font-normal dark:text-gray-100">in {{ itm.item.route }}</p>
                </div>
                <div class="mt-2 flex">
                  <div class="flex items-center text-sm dark:text-gray-100">
                    <CalendarIcon class="flex-shrink-0 mr-1.5 h-5 w-5 dark:text-gray-200" aria-hidden="true" />
                    <p>
                      Startet am
                      {{ ' ' }}
                      <time :datetime="timeRange">{{ timeRange }}</time>
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                <div class="flex">
                  <span v-for="(partner, partnerIdx) in partners" :key="partnerIdx" class="flex h-6 w-6 rounded-full ring-1 ring-white text-sm justify-center items-center" >{{ partner }}</span>
                </div>
              </div>
            </div>
            <div class="ml-5 flex-shrink-0">
              <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </a>
      </li>
</template>

<script setup>
import { CalendarIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import {computed} from "vue";
import {useAuthStore} from "@/stores";
import moment from "moment";
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

const authStore = useAuthStore();

const props = defineProps({
  itm: Object
})

const timeRange = computed(() => {

  const times = props.itm.steps.filter((step) => step.users.some((user) => user.id === authStore.user.id));
  const minDate = moment(times[0].startDate);
  const maxDate = moment(times[times.length -1].endDate);
  return minDate.format('DD.MM.yyyy HH:mm') + ' - ' + maxDate.format('HH:mm');

})

const partners = computed(() => {
  let users = [];
  props.itm.steps.map((item) => item.users.map((u) => users.push(u.first_name[0] + u.name[0])))
  return Array.from(new Set(users));
})

</script>