<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-teal-600.svg" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-100">Gib dein neues passwort ein!</h2>

      </div>
      <Form @submit="onSubmit" :validation-schema="schema" class="mt-8 space-y-6" v-slot="{ errors, isSubmitting }">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email" class="sr-only">Deine E-Mail Adresse:</label>
            <Field placeholder="Email Adresse" name="email" type="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm" :class="{ 'is-invalid': errors.email }" />
          </div>
          <div>
            <label for="password" class="sr-only">Deine neues Passwort:</label>
            <Field placeholder="Passwort" name="password" type="password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm" :class="{ 'is-invalid': errors.password }" />
          </div>
          <div>
            <label for="password_confirmation" class="sr-only">Passwort bestätigen</label>
            <Field placeholder="Passwort bestätigen" name="password_confirmation" type="password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-teal-500 focus:border-teal-500 focus:z-10 sm:text-sm" :class="{ 'is-invalid': errors.password }" />
          </div>
        </div>

        <div>
          <button :disabled="isSubmitting" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-teal-500 group-hover:text-teal-400" aria-hidden="true" />
            </span>
            Passwort zurücksetzen
          </button>
        </div>
        <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{errors.apiError}}</div>
      </Form>

    </div>
  </div>
</template>

<script setup>
import * as Yup from "yup";
import { Form, Field } from 'vee-validate';
import {useAuthStore} from "@/stores";
import {useRoute} from "vue-router";

const schema = Yup.object().shape({
  password: Yup.string().required('Das Passwort wird benötigt'),
  email: Yup.string().required('Die Email Adresse wird benötigt'),
});
const route = useRoute();
function onSubmit(values, { setErrors }) {
  const authStore = useAuthStore();
  const { email, password } = values;

  return authStore.changePassword(email, password, route.query.token)
      .catch(error => setErrors({ apiError: error }));
}
</script>