<template>
  <div>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 flex z-40">
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img class="h-8 w-auto" src="/logo.png" alt="ÖZ Planer" />
              </div>
              <nav class="mt-5 px-2 space-y-1">
                <router-link :to="item.href" v-for="item in navigation.filter(x => authStore.hasPermission(x.permission))" :key="item.name" active-class="bg-gray-900 text-white" class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md" aria-hidden="true">
                  <component :is="item.icon" class="text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
                <a class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md" @click="authStore.logout()" href="#">
                  <component :is="LogoutIcon" class="text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                  Logout
                </a>
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <a href="#" class="flex-shrink-0 group block">
                <div class="flex items-center">
                  <div>
                    <span class="flex h-10 w-10 rounded-full border border-gray-200 justify-center items-center p-1">{{ authStore.user.first_name[0] }} {{ authStore.user.name[0] }}</span>
                  </div>
                  <div class="ml-3">
                    <p class="text-base font-medium text-white">{{ authStore.user.first_name }}</p>
                    <p class="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                  </div>
                </div>
              </a>
            </div>
          </DialogPanel>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
      <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <img class="h-8 w-auto" src="/logo.png" alt="ÖZ Planer" />
        </div>
        <nav class="mt-5 flex-1 px-2 space-y-1">
          <router-link :to="item.href" v-for="item in navigation.filter(x => authStore.hasPermission(x.permission))" :key="item.name" active-class="bg-gray-900 text-white" class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md" aria-hidden="true">
            <component :is="item.icon" class="text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
            {{ item.name }}
          </router-link>
          <a class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md" @click="authStore.logout()" href="#">
            <component :is="LogoutIcon" class="text-gray-400 group-hover:text-gray-300 mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
            Logout
          </a>
        </nav>
      </div>
      <div class="flex-shrink-0 flex p-4 bg-teal-800">
        <a href="#" class="flex-shrink-0 w-full group block">
          <div class="flex items-center">
            <div>
              <span class="flex h-9 w-9 rounded-full border border-gray-200 justify-center items-center p-1">{{ authStore.user.first_name[0] }} {{ authStore.user.name[0] }}</span>
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-white">{{ authStore.user.first_name }}</p>
              <p class="text-xs font-medium text-gray-300 group-hover:text-gray-200">View profile</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  </div>
</template>

<script setup>
  import {CalendarIcon, HomeIcon, InboxIcon, UsersIcon, LogoutIcon, XIcon} from "@heroicons/vue/outline";
  import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { useAuthStore} from "@/stores";
  import {ref, defineEmits, defineProps, watch} from "vue";
  const authStore = useAuthStore();

  const navigation = [
    { name: 'Home', href: '/', icon: HomeIcon, permission: ""},
    { name: 'Benutzer', href: '/users', icon: UsersIcon, permission: "create_users" },
    { name: 'Kalender', href: '/calendar', icon: CalendarIcon, permission: ""},
    { name: 'Geräte', href: '/items', icon: InboxIcon, permission: "create_users"},
  ]



  const props = defineProps({
    'sidebarOpen': Boolean
  });
  const sidebarOpen = ref(false);

  watch(() => props.sidebarOpen, () => {
    sidebarOpen.value = props.sidebarOpen;
  })

  const emit = defineEmits(['sidebar-closed']);

  watch(sidebarOpen, () => {
    if(sidebarOpen.value === false) {
      emit("sidebar-closed");
    }
  });

</script>