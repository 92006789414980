<template>
<div>
  <div class="py-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-4">
      <h1 class="text-2xl font-semibold dark:text-gray-100">Kalender</h1>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 rounded">
      <div>
        <h2 class="text-lg font-semibold dark:text-gray-100">Geplante Einsätze</h2>
        <div class="lg:grid lg:grid-cols-12 lg:gap-x-16">
          <div class="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
            <div class="flex items-center dark:text-gray-100">
              <button @click="prevMonth" type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 dark:text-gray-400 hover:text-gray-500">
                <span class="sr-only">Previous month</span>
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <div class="flex-auto font-semibold">{{ monthName }} {{ year }}</div>
              <button @click="nextMonth" type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 dark:text-gray-400 hover:text-gray-500">
                <span class="sr-only">Next month</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-6 grid grid-cols-7 text-xs leading-6 dark:text-gray-200">
              <div>Mo.</div>
              <div>Di.</div>
              <div>Mi.</div>
              <div>Do.</div>
              <div>Fr.</div>
              <div>Sa.</div>
              <div>So.</div>
            </div>
            <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-300 dark:bg-slate-600 text-sm shadow ring-1 ring-gray-200 dark:ring-slate-600">
              <button @click="setSelectedDate(day)" v-for="(day, dayIdx) in days" :key="day.date" type="button" :class="['py-1.5 hover:bg-gray-100 focus:z-10 dark:text-gray-100 dark:hover:bg-slate-600 dark:border-slate-600', day.isCurrentMonth ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-600', (day.isSelected || day.isToday) && 'font-semibold', day.isSelected && 'text-white', !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900', !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400', day.isToday && !day.isSelected && 'text-teal-600', dayIdx === 0 && 'rounded-tl-lg', dayIdx === 6 && 'rounded-tr-lg', dayIdx === days.length - 7 && 'rounded-bl-lg', dayIdx === days.length - 1 && 'rounded-br-lg']">
                <time :datetime="day.date" :class="['mx-auto flex h-7 w-7 items-center justify-center rounded-full', day.isSelected && day.isToday && 'bg-teal-600', day.isSelected && !day.isToday && 'bg-gray-900']">{{ day.date.split('-').pop().replace(/^0/, '') }}</time>
              </button>
            </div>
            <button v-if="authStore.hasPermission('create_events')" @click="showCreateEvent" type="button" class="mt-8 w-full rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white shadow hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2">Einsatz Planen</button>
          </div>
          <ol class="mt-4 divide-y divide-gray-100 dark:divide-slate-800 text-sm leading-6 lg:col-span-7 xl:col-span-8">
            <li v-for="event in events" :key="event.id" @click="showSteps(event)" class="relative flex space-x-6 py-6 xl:static hover:bg-gray-300 dark:hover:bg-slate-600 cursor-pointer">
              <div class="flex-auto">
                <h3 class="pr-10 font-semibold dark:text-gray-100 xl:pr-0">{{ event.item.name }}</h3>
                <dl class="mt-2 flex flex-col dark:text-gray-300 xl:flex-row">
                  <div class="flex items-start space-x-3">
                    <dt class="mt-0.5">
                      <span class="sr-only">Date</span>
                      <CalendarIcon class="h-5 w-5 dark:text-gray-300" aria-hidden="true" />
                    </dt>
                    <dd>
                      <time :datetime="event.startDate">{{ moment(event.startDate).format('DD. MMMM') }} um {{ moment(event.startDate).format('HH:mm') }}</time>
                      -
                      <time :datetime="event.endDate">{{ moment(event.endDate).format('DD. MMMM') }} um {{ moment(event.endDate).format('HH:mm') }}</time>
                    </dd>
                  </div>
                  <div class="mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                    <dt class="mt-0.5">
                      <span class="sr-only">Location</span>
                      <LocationMarkerIcon class="h-5 w-5 dark:text-gray-300" aria-hidden="true" />
                    </dt>
                    <dd>{{ event.item.route }}</dd>
                  </div>
                </dl>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <modal-component :open="createEventModal" @modalClosed="createEventModal = false">
    <template v-slot:title><span>Einsatz planen</span></template>
    <template v-slot:description>Einen neuen einsatz für einen Trolley oder Stand planen</template>
    <template v-slot:content>
      <div class="space-y-6 pt-6 pb-5">
        <div>
          <label for="project-name" class="block text-sm font-medium text-gray-900 dark:text-gray-100"> Wann soll der Einsatz sein? </label>
          <div class="mt-1">
            <Datepicker id="auto" v-model="date" range auto-range="0" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
          </div>
        </div>
        <div>
          <label for="description" class="block text-sm font-medium text-gray-900 dark:text-gray-100"> Welches Gerät soll dafür verwendet werden? </label>
          <div class="mt-1">
            <select-component v-if="items.length > 0" v-model="selectedItem" :items="items"></select-component>
          </div>
        </div>
      </div>
      <div class="flex flex-shrink-0 justify-end px-4 py-4">
        <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2" @click="applicationStore.closeModal()">Cancel</button>
        <button type="button" @click="saveEvent" class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2">Save</button>
      </div>
    </template>
  </modal-component>
  <event-modal :open="showStepsModal" :event="evt" :selected="selectedTimes" @event-destroyed="loadEvents" @modal-closed="showStepsModal = false"></event-modal>

</div>
</template>

<script setup>
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon, LocationMarkerIcon } from '@heroicons/vue/solid'
import {computed, ref, getCurrentInstance, onMounted, onActivated} from 'vue'
import moment from 'moment'
import {fetchWrapper} from "@/helpers";
import ModalComponent from "@/components/ModalComponent";
import {useApplicationStore, useAuthStore} from "@/stores";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import SelectComponent from "@/components/SelectComponent";
import EventModal from "@/components/EventModal";
import {notify} from "notiwind";
moment.locale("de");
const applicationStore = useApplicationStore();
const authStore = useAuthStore();
const today = ref(moment());
const currentMonth = moment().month();
const currentDate = moment().date();
const instance = getCurrentInstance();
let monthName = ref(today.value.format('MMMM'));
let year = ref(today.value.year())
const selectedTimes = ref([]);
const date = ref();
const createEventModal = ref(false);
const showStepsModal = ref(false);
const items = ref([]);
const selectedItem = ref(null);
const events = ref([]);
const evt = ref({});


function nextMonth(){
  today.value = moment(today.value.add(1, 'month').format('YYYY-MM-DD'));
  monthName.value = today.value.format('MMMM');
  year.value = today.value.year()
  //instance?.proxy?.$forceUpdate();
}

function prevMonth(){
  today.value = moment(today.value.subtract(1, 'month').format('YYYY-MM-DD'));
  monthName.value = today.value.format('MMMM');
  year.value = today.value.year()
  //instance?.proxy?.$forceUpdate();
}

async function loadEvents(){
  events.value = await fetchWrapper.get('events');
}

let selectedDay = ref(today.value.date());
const days = computed(() => {
  const daysInMonth = today.value.daysInMonth();
  const firstOfMonth = moment(today.value.format('YYYY-MM') + '-01');
  const weekday = firstOfMonth.isoWeekday();
  const result = [];

  if(weekday !== 1){
    for(let i = 1; i < weekday; i++){
      result.push({
        date: firstOfMonth.subtract(1,'days').format('YYYY-MM-DD'),
        isCurrentMonth: false,
        isSelected: false,
        isToday: false
      })
    }
  }
  for(let i = 1; i <= daysInMonth; i++){
    result.push({
      date: today.value.format('YYYY-MM') +'-' + (i < 10 ? '0' + i: i),
      isCurrentMonth: true,
      isSelected: selectedDay.value === i,
      isToday: currentMonth === today.value.month() && currentDate === i
    })
  }

  const lastOfMonth = moment(result[result.length -1].date);
  if(lastOfMonth.isoWeekday() !== 7){
    const missing = 8 - lastOfMonth.isoWeekday();
    for(let i = 1; i < missing; i++){
      result.push({
        date: lastOfMonth.add(1,'days').format('YYYY-MM-DD'),
        isCurrentMonth: false,
        isSelected: false,
        isToday: false
      })
    }
  }

  return result
});
//const activeDay = ref({})
const setSelectedDate = function(day){
  for(let d of days.value){
    d.isSelected = false;
  }
  day.isSelected = true;
  //activeDay.value = day
  loadData(day);
  instance?.proxy?.$forceUpdate();
}

async function loadData(day){
  events.value = (await fetchWrapper.get('events/?date=' + day.date));
}

async function saveEvent(){
  const resp = (await fetchWrapper.post('events/', {
    startDate: moment(date.value[0]).format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment(date.value[1]).format('YYYY-MM-DD HH:mm:ss'),
    pm_item_id: selectedItem.value
  }));
  events.value.push(resp);
  notify({
    group: "success",
    title: "Yeah!",
    text: "Der Einsatz wurde erfolgreich gespeichert"
  })
  createEventModal.value = false;
}

async function showSteps(event){
  //instance?.proxy?.$forceUpdate();
  //showStepsModal.value = false;
  createEventModal.value = false;
  showStepsModal.value = true;
  evt.value = await fetchWrapper.get('events/' + event.id)
  selectedTimes.value = [];
  for(let i = 0; i < evt.value.steps.length; i++){
    if(evt.value.steps[i].users.filter(x => x.id === authStore.user.id).length > 0){
      selectedTimes.value.push(evt.value.steps[i].id);
    }
  }
}

function showCreateEvent(){
  //createEventModal.value = false;
  showStepsModal.value = false;
  createEventModal.value = true;
}

onMounted(async () => {
  items.value = (await fetchWrapper.get('items/'));
  events.value = (await fetchWrapper.get('events/'));
  selectedItem.value = items.value[0].id;
})

onActivated(async () => {
  items.value = (await fetchWrapper.get('items/'));
  events.value = (await fetchWrapper.get('events/'));
  selectedItem.value = items.value[0].id;
})


</script>

<style scoped>

</style>