import { defineStore } from 'pinia';

import { fetchWrapper, router } from '@/helpers';
import {notify} from "notiwind";

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        token: localStorage.getItem('token'),
        permissions: JSON.parse(localStorage.getItem('permissions')),
        refreshTried: false,
        returnUrl: null
    }),
    actions: {
        async login(email, password) {
            const resp = await fetchWrapper.post(`login`, { email, password });
            // update pinia state
            this.user = resp.user;
            this.token = resp.access_token;
            this.permissions = resp.permissions;
            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(resp.user));
            localStorage.setItem('token',resp.access_token);
            localStorage.setItem('permissions',JSON.stringify(resp.permissions));
            this.refreshTried = false;
            // redirect to previous url or default to home page
            router.push(this.returnUrl || '/');
        },
        async reset(email) {
            const resp = await fetchWrapper.post(`reset`, { email });
            if(resp.message === "success"){
                notify({
                    group: 'success',
                    title: 'Yeay',
                    text: "Dein Passwort wurde zurückgesetzt. Schaue in deine Emails!"
                })
            }
        },
        async changePassword(email,password,token) {
            const resp = await fetchWrapper.post(`password/reset`, {
                "email": email,
                "password": password,
                "password_confirmation": password,
                "token": token
            });
            if(resp.status === "passwords.reset") {
                notify({
                    group: 'success',
                    title: 'Yeay',
                    text: "Dein Passwort wurde geändert!"
                });
                router.push('/');
            }
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            router.push('/login');
        },
        async refreshToken(){
            this.refreshTried = true;
            try {
                const resp = await fetchWrapper.post(`login`, {});
                this.user = resp.user;
                this.token = resp.access_token;
                localStorage.setItem('token', resp.access_token);
                localStorage.setItem('user', JSON.stringify(resp.user));
            }
            catch (e) {
                this.user = null;
                this.token = null;
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                router.push('/login');
            }
        },
        hasPermission(name){
            return name === "" || this.permissions.some(x => x.name === name);
        }
    }
});