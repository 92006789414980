<template>
<div>
  <div class="py-6">
    <action-panel :open="openAction" @action-closed="openAction=false">
      <template v-slot:title>Benutzer löschen?</template>
      <template v-slot:message>
        Wenn du den Benutzer löschst, werden auch die bestehenden Einsätze gelöscht.<br>
        Möchtest du diesen Benutzer wirklich löschen?
      </template>
      <template v-slot:buttons>
        <button type="button" @click="openAction = false" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Abbrechen</button>
        <button type="button" @click="destroyUser(); openAction = false" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm">Löschen</button>
      </template>
    </action-panel>
    <header-component class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-3">
      <template v-slot:title>Benutzer</template>
      <template v-slot:buttons>
        <button type="button" @click="openModal = true; user = {}; selected = []" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-teal-500">Neuer Benutzer</button>
        <button type="button" @click="openImportModal = true;" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-teal-500">Benutzer Importieren</button>
      </template>
    </header-component>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <!-- Replace with your content -->
      <div class="py-4">
        <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <li v-for="user in users" :key="user.id" class="col-span-1 bg-white dark:bg-slate-600 rounded-lg shadow divide-y divide-gray-200 dark:divide-slate-700">
            <div class="w-full flex items-center justify-between p-6 space-x-6">
              <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                  <h3 class="text-gray-900 dark:text-gray-100 text-sm font-medium truncate">{{ user.first_name }} {{ user.name }}</h3>
                </div>
                <p class="mt-1 text-gray-500 dark:text-gray-300 text-sm truncate">{{ user.middle_name }}</p>
              </div>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-gray-200 dark:divide-slate-700">
                <div class="w-0 flex-1 flex">
                  <div @click="openModal = true; usr = user; selected = usr.roles" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer">
                    <PencilIcon class="w-5 h-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
                    <span class="ml-3 dark:text-gray-100">Bearbeiten</span>
                  </div>
                </div>
                <div class="-ml-px w-0 flex-1 flex">
                  <div @click="openAction = true; idToDestroy = user.id " class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 cursor-pointer">
                    <TrashIcon class="w-5 h-5 text-red-400" aria-hidden="true" />
                    <span class="ml-3 text-red-400">Löschen</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- /End replace -->
    </div>
  </div>
  <modal-component :open="openModal" @close="openModal = false">
    <template v-slot:title>Benutzer erstellen / bearbeiten</template>
    <template v-slot:description>Hier kannst du einen Benutzer hinzufüge oder bearbeiten</template>
    <template v-slot:content>
      <div>
        <label for="project-name" class="block text-sm font-medium text-gray-900 dark:text-gray-100">Vorname</label>
        <div class="mt-1">
          <input type="text" v-model="usr.first_name" name="first_name" id="first_name" class="block w-full rounded-md dark:bg-slate-600 border-gray-300 dark:border-slate-600 dark:text-gray-100 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
        </div>
      </div>
      <div>
        <label for="project-name" class="mt-3 block text-sm font-medium text-gray-900 dark:text-gray-100">Name</label>
        <div class="mt-1">
          <input type="text" v-model="usr.name" name="name" id="name" class="block w-full rounded-md dark:bg-slate-600 border-gray-300 dark:border-slate-600 dark:text-gray-100 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
        </div>
      </div>
      <div>
        <label for="project-name" class="mt-3 block text-sm font-medium text-gray-900 dark:text-gray-100">E-Mail</label>
        <div class="mt-1">
          <input type="text" v-model="usr.email" name="email" id="email" class="block w-full rounded-md dark:bg-slate-600 border-gray-300 dark:border-slate-600 dark:text-gray-100 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
        </div>
      </div>
      <Listbox as="div" v-model="selected" multiple>
        <ListboxLabel class="mt-3 block text-sm font-medium text-gray-700 dark:text-gray-100"> Rollen:</ListboxLabel>
        <div class="mt-1 relative text-black">
          <ListboxButton class="relative w-full bg-white dark:bg-slate-600 border border-gray-300 dark:border-slate-600 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500 sm:text-sm">
            <span class="block truncate dark:text-gray-100">{{ selected.map((person) => person.name).join(', ') }}</span>
            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="h-5 w-5 text-gray-400 dark:text-gray-100" aria-hidden="true" />
        </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              <ListboxOption as="template" v-for="role in roles" :key="role.id" :value="role" v-slot="{ active, selected }">
                <li :class="[active ? 'text-white bg-teal-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-8 pr-4']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ role.name }}
              </span>

                  <span v-if="selected" :class="[active ? 'text-white' : 'text-teal-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <div class="flex flex-shrink-0 justify-end px-4 py-4">
        <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2" @click="openModal = false">Abbrechen</button>
        <button @click="saveUser" type="submit" class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2">Speichern</button>
      </div>
    </template>
  </modal-component>
  <modal-component :open="openImportModal" @close="openImportModal = false">
    <template v-slot:title>Benutzer Importieren</template>
    <template v-slot:description>Benutzer von VS-Stahnsdorf Importieren</template>
    <template v-slot:content>
      <fieldset>
        <legend class="text-lg font-medium text-gray-900">Members</legend>
        <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
          <div v-for="userToImport in usersToImport" :key="userToImport.id" class="relative flex items-start py-4">
            <div class="min-w-0 flex-1 text-sm">
              <label :for="`person-${userToImport.id}`" class="font-medium text-gray-700 select-none">{{ userToImport.vorname }} {{ userToImport.name }}</label>
            </div>
            <div class="ml-3 flex items-center h-5">
              <input :id="`person-${userToImport.id}`" :value="userToImport.id" v-model="importTheseUsers" :name="`person-${userToImport.id}`" type="checkbox" class="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 rounded" />
            </div>
          </div>
        </div>
      </fieldset>
      <div class="flex flex-shrink-0 justify-end px-4 py-4">
        <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2" @click="openImportModal = false">Abbrechen</button>
        <button @click="importUsers" type="submit" class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2">Importieren</button>
      </div>
    </template>
  </modal-component>
</div>
</template>

<script setup>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon, PencilIcon, TrashIcon } from '@heroicons/vue/solid'
import HeaderComponent from './HeaderComponent'
import ActionPanel from './ActionPanel'
import ModalComponent from "@/components/ModalComponent";
import {onActivated, onMounted, ref, watch} from "vue";
import {fetchWrapper} from "@/helpers";
import {notify} from "notiwind";

const openImportModal = ref(false);
const openModal = ref(false);
const openAction = ref(false);
const usersToImport = ref([]);
const users = ref([]);
const importTheseUsers = ref([]);
const idToDestroy = ref();
const usr = ref({});
watch(openImportModal, async () => {
  usersToImport.value = await fetchWrapper.get("users/import");
});

async function saveUser(){
  let id = usr.value.id || "";
  usr.value.roles = selected;
  if(id === '') {
    users.value = await fetchWrapper.post('users/', usr.value);
  }else{
    users.value = await fetchWrapper.put('users/' + id, usr.value );
  }
  notify({
    group: "success",
    title: "Yeah!",
    text: "Der benutzer wurde gespeichert"
  })
}
const roles = ref([]);
const selected = ref([]);

async function importUsers(){
  users.value = await fetchWrapper.post('users/import', {ids: importTheseUsers.value });
  notify({
    group: "success",
    title: "Yeah!",
    text: "Die Benutzer wurden Importiert"
  })
}

onMounted(async ()=> {
  users.value = await fetchWrapper.get('users/');
  roles.value = await fetchWrapper.get('roles/');
})

onActivated(async () => {
  users.value = await fetchWrapper.get('users/');
  roles.value = await fetchWrapper.get('roles/');
})

async function destroyUser(){
  users.value = await fetchWrapper.delete('users/' + idToDestroy.value, {});
  notify({
    group: "success",
    title: "Yeah!",
    text: "Der Benutzer wurde gelöscht"
  })
}

</script>

<style scoped>

</style>