import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuthStore } from '@/stores';
import Home from "@/components/HomeView";
import calendar from "@/components/CalendarView";
import users from "@/components/UsersView";
import items from "@/components/ItemsView";
import login from "@/components/LoginView";
import passwordReset from "@/components/ResetPasswordView";
import changePassword from "@/components/ChangePasswordView";


export const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: 'active',
    routes: [
        { path: '/', component: Home },
        { path: '/calendar', component: calendar },
        { path: '/users', component: users },
        { path: '/items', component: items },
        { path: '/login', component: login },
        { path: '/reset/password', component: passwordReset},
        { path: '/password/change/', component: changePassword}
    ]
});

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login','/reset/password','/password/change/'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.user) {
        auth.returnUrl = to.fullPath;
        return '/login';
    }
});