<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10" @close="isOpen = false">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="bg-teal-700 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"><slot name="title"></slot></DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="rounded-md bg-teal-700 text-teal-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="isOpen = false">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-teal-300"><slot name="description"></slot></p>
                    </div>
                  </div>
                  <div class="relative flex-1 py-6 px-4 sm:px-6 text-black dark:bg-slate-800">
                    <!-- Replace with your content -->
                    <slot name="content"></slot>
                    <!-- /End replace -->
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {defineEmits, defineProps, ref, watch} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
//import {useApplicationStore} from "@/stores";
const props = defineProps({
  'open': Boolean
});
const isOpen = ref(props.open)

watch(() => props.open, () => {
  isOpen.value = props.open;
})

const emit = defineEmits(['modal-closed']);

watch(isOpen, () => {
  if(isOpen.value === false) {
    emit("modal-closed");
  }
});

</script>