<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Listbox as="div" v-model="selectedItem">
    <div class="mt-1 relative text-black dark:text-gray-100">
      <ListboxButton class="bg-white dark:bg-slate-600 relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500 sm:text-sm">
        <span class="block truncate">{{ selectedItem.name }} ({{ selectedItem.route }})</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="item in items" :key="item.id" :value="item" v-slot="{ active, selected: selectedItem }">
            <li :class="[active ? 'text-white bg-teal-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
              <span :class="[selectedItem ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ item.name }} ({{ item.route }})
              </span>

              <span v-if="selectedItem" :class="[active ? 'text-white' : 'text-teal-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import {defineEmits, defineProps, ref, watch} from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

const props = defineProps({
  items: Array,
  modelValue: String
});

const selectedItem = ref(props.items[0]);
const emit = defineEmits(['update:modelValue']);

watch(selectedItem, () => {
  emit("update:modelValue", selectedItem.value.id)
});


</script>