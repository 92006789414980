import {createApp} from 'vue'
import { createPinia } from 'pinia';
import { useAuthStore } from '@/stores';
import {router} from "@/helpers/router";
import Notifications from 'notiwind'
import App from './App.vue'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'


const app = createApp(App);

app.use(createPinia());
router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login','/reset/password','/password/change/'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.user) {
        auth.returnUrl = to.fullPath;
        return '/login';
    }
});
app.use(router);
app.use(Notifications)
app.use(OneSignalVuePlugin, {
    appId: "8b7214d8-fcb8-4c64-95b2-a1253e101ed9",
    safari_web_id: "web.onesignal.auto.65a2ca34-f112-4f9d-a5c6-253c0b61cb9f",
    allowLocalhostAsSecureOrigin: true,
    promptOptions: {
        slidedown: {
            prompts: [
                {
                    type: "push",
                    autoPrompt: false,
                    text: {
                        actionMessage: "Möchtest du an bevorstehende Einsätze erinnert werden?",
                        acceptButton: "Ja bitte!",
                        cancelButton: "Nein Danke"
                    }
                }
            ]
        }

    }
});

app.mount('#app')

