<template>
  <div>
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 class="text-2xl font-semibold dark:text-gray-100">Home</h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
        <h2 class="text-lg font-semibold dark:text-gray-100">Meine ÖZ Einsätze</h2>
        <list-element>
          <template v-for="event in events" :key="event.id">
            <trolley-list-item :itm="event" @click="openEventModal = true; evt = event"></trolley-list-item>
          </template>
        </list-element>
        <hr class="mt-3 mb-3">
        <h2 class="text-lg font-semibold dark:text-gray-100">Verfügbare Termine für ÖZ</h2>
        <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
          <li v-for="availableDate in availableEvents" :key="availableDate.id" @click="openEventModal = true; evt=availableDate" class="col-span-1 flex shadow-sm rounded-md">
            <div class="bg-teal-500 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
              {{ availableDate.item.name[0] }}
            </div>
            <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 dark:border-slate-600 bg-white dark:bg-slate-600 rounded-r-md truncate">
              <div class="flex-1 px-4 py-2 text-sm truncate">
                <a href="#" class="text-gray-900 dark:text-gray-100 font-medium hover:text-gray-600">{{ availableDate.item.name }}</a>
                <p class="text-gray-500 dark:text-gray-300">{{ moment(availableDate.startDate).format('DD.MM.yyyy') }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <event-modal :open="openEventModal" :selected="selected" :event="evt" @modal-closed="openEventModal = false; updateData()"></event-modal>
</template>

<script setup>
import ListElement from "@/components/ListElement";
import TrolleyListItem from "@/components/TrolleyListItem";
import {computed, onActivated, onDeactivated, onMounted, ref} from "vue";
import moment from "moment";
import {fetchWrapper} from "@/helpers";
import EventModal from "@/components/EventModal";
import {useAuthStore} from "@/stores";
import {useOneSignal} from "@onesignal/onesignal-vue3";
import {io} from "socket.io-client";
const authStore = useAuthStore();
const availableEvents = ref([]);
const events = ref([]);
const evt = ref({});
const oneSignal = useOneSignal();
console.log(process.env.VUE_APP_SOCKET_PATH);
const socket = io(process.env.VUE_APP_SOCKET_PATH, {
  path: '/ws',
  auth: {
    token: authStore.token
  }
});

console.log(socket);

socket.on('eventChanged', (message) => {
  //console.log(message);
  const otherItem = JSON.parse(message);
  const item = events.value.find(x => x.id === otherItem.id);
  if(item === undefined || item === null) {
    if(otherItem.steps.some(x => x.users.some(y => y.id === authStore.user.id))) {
      events.value.push(otherItem);
    }
  }else {
    if(!otherItem.steps.some(x => x.users.some(y => y.id === authStore.user.id))){
      events.value = events.value.filter(x => x.id !== otherItem.id);
    }else {
      const keys = Object.keys(otherItem);
      keys.forEach(x => item[x] = otherItem[x]);
    }
  }
  const item2 = availableEvents.value.find(x => x.id === otherItem.id);
  if(item2 !== undefined && item2 !== null) {
    const keys = Object.keys(otherItem);
    keys.forEach(x => item2[x] = otherItem[x]);
  }
});


const selected = computed(() =>{
  if(Object.keys(evt.value).length === 0){
    return []
  }
  const selectedTimes = [];
  for(let i = 0; i < evt.value.steps.length; i++){
    if(evt.value.steps[i].users.filter(x => x.id === authStore.user.id).length > 0){
      selectedTimes.push(evt.value.steps[i].id);
    }
  }
  return selectedTimes
})
const openEventModal = ref(false)

oneSignal.on('subscriptionChange', async (isSubscribed) => {
  if (isSubscribed) {
    const userId = await oneSignal.getUserId();
    if (userId !== undefined && userId !== '') {
      await fetchWrapper.post('users/updateExternalId/', { externalId: userId});
    }
  }
});

async function updateData(){
  const resp = await fetchWrapper.get('events/dashboard');
  events.value = resp.events;
  availableEvents.value = resp.availableEvents;
}

onMounted(async () => {
  await updateData();
  document.addEventListener('visibilitychange',async () => {
    if(!document.hidden){
      await updateData();
    }
  });
  await oneSignal.showSlidedownPrompt();
});

onActivated(async () => {
  await updateData();
  document.addEventListener('visibilitychange',async () => {
    if(!document.hidden){
      await updateData();
    }
  });
})

onDeactivated(() => {
  document.removeEventListener('visibilitychange');
})

</script>

<style scoped>

</style>
